var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"main",staticClass:"hero",class:{ 'is-ie': _vm.isIE },style:({ height: (_vm.pageHeight + "px") })},[_c('div',{ref:"animated",staticClass:"left-background",style:({
      clipPath: _vm.leftBackgroundClipPath,
      '-webkit-clip-path': _vm.leftBackgroundClipPath,
      height: (_vm.pageHeight + "px")
    })}),_c('div',{staticClass:"left"},[_c('h1',[_vm._v("Callum Macrae")]),_c('div',{staticClass:"left__content"},[_c('p',[_vm._v(" I'm a developer and occasional musician based in London, UK, with a passion for using JavaScript to solve complicated problems. My current enthusiasms are Vue and SVGs (but only sometimes at the same time). ")]),_vm._m(0),_c('a',{staticClass:"down-arrow",attrs:{"role":"presentation"},on:{"click":_vm.handleScrollClick}},[_c('FontAwesomeIcon',{staticClass:"fa-2x",attrs:{"icon":['fas', 'arrow-down'],"mask":['fas', 'circle'],"transform":"shrink-6"}})],1),_c('div',{staticClass:"social-links"},[_c('a',{attrs:{"href":"https://twitter.com/callumacrae","target":"_blank","rel":"noopener"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Twitter")]),_c('FontAwesomeIcon',{attrs:{"icon":['fab', 'twitter']}})],1),_c('a',{attrs:{"href":"https://github.com/callumacrae","target":"_blank","rel":"noopener"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Github")]),_c('FontAwesomeIcon',{attrs:{"icon":['fab', 'github']}})],1),_c('a',{attrs:{"href":"https://codepen.io/callumacrae","target":"_blank","rel":"noopener"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Codepen")]),_c('FontAwesomeIcon',{attrs:{"icon":['fab', 'codepen']}})],1)])])]),_c('div',{staticClass:"right"},[_c('h1',{ref:"rightTitleEl",style:({
        clipPath: _vm.rightTitleClipPath,
        '-webkit-clip-path': _vm.rightTitleClipPath
      }),attrs:{"role":"presentation"}},[_vm._v(" Callum Macrae ")]),_c('div',{staticClass:"right__content"},[_c('a',{attrs:{"href":"https://twitter.com/callumacrae","target":"_blank","rel":"noopener"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Twitter")]),_c('FontAwesomeIcon',{attrs:{"icon":['fab', 'twitter']}})],1),_c('a',{attrs:{"href":"https://github.com/callumacrae","target":"_blank","rel":"noopener"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Github")]),_c('FontAwesomeIcon',{attrs:{"icon":['fab', 'github']}})],1),_c('a',{attrs:{"href":"https://codepen.io/callumacrae","target":"_blank","rel":"noopener"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Codepen")]),_c('FontAwesomeIcon',{attrs:{"icon":['fab', 'codepen']}})],1)])]),_c('GlobalEvents',{attrs:{"target":"window"},on:{"resize":_vm.handlePageResize}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" I write and speak, some of which you can find below. I'm also available for work: contact me at "),_c('a',{attrs:{"href":"mailto:callum@macr.ae"}},[_vm._v("callum@macr.ae")]),_vm._v(". ")])}]

export { render, staticRenderFns }